import React from "react"
import bulmaCollapsible from "@creativebulma/bulma-collapsible/src/js"

const FAQItems = [
  {
    header: 'Do you have a privacy policy? Do you take our passwords / sensitive data?',
    text: <p>Check it here - <a href='https://privacy.beatshine.com' target={"_blank"}>Beatshine Privacy Policy</a>. We do not take your passwords. You will never be asked anywhere to give password or any sensitive data. We are a chrome extension and it is just like another human following, liking and commenting.</p>
  },
  {
    header: 'Is it safe? Can I get blocked?',
    text: 'We have been testing this on several accounts for the past 1 month and IF YOU DON’T SPAM, you should not get blocked. Beatshine is a very powerful tool. If you send positive messages, good comments, follow and like tracks using beatshine, chances of you getting banned are very less. We are imitating human behaviour and hence it is not possible for beatstars to detect unless people report you if you spam. (None of our account was banned!). But if people report you for spam, Beatstars would send you a warning mail first and you might get banned after that.  Don\'t say things like "Check out my beats" "buy my beats" "come to my page" instead you need to say things like "Hey I followed you lets support each other. Follow me back"\n' +
      'Show that you are there to help them. Provide value for the other person, do not try to take in your first interaction.'
  },
  {
    header: 'People on fb groups and gram are telling me to not use Beatshine? Should I use it?',
    text: 'You should see the results yourself and then listen to other. All those who are telling you this are big producers who don’t want to let you have this pot of gold! Don\'t say things like "Check out my beats" "buy my beats" "come to my page" instead you need to say things like "Hey I followed you lets support each other. Follow me back"\n' +
      'Show that you are there to help them. Provide value for the other person, do not try to take in your first interaction. When you like someone’s track, leave a comment, message them about how good their beats are, you give them an adrenaline rush and you get the same. It’s support for support. We are just automating this adrenaline rush on your behalf. This has been there on Instagram, Facebook, Spotify and any social network. A lot of people have grown from hundreds to hundreds of thousands on instagram and other social networks. Join in early to use this to full extent. How much anyone says, Beatstars cannot remove thousands of payed accounts. Even for Instagram it took them 4 years to stop automation on their platform. In the end it'
  },
  {
    header: 'What are tasks on beatstars?',
    text: <p>
      Tasks refer to:<br />
      1. Sending Message<br />
      2. Posting Comments on Tracks<br />
      3. Following a user<br />
      4. Unfollowing a user<br />
      5. Liking a track
    </p>
  },
  {
    header: 'What are personalised messages and comments?',
    text: 'While storing messages & comments templates use {username} or {name}. For eg: “Hey {username}, your beats are dope!” Would be sent as “Hey Ronaldo, your beats are dope!” for the username @ronaldo on beatstars.'
  },
  {
    header: 'Does my computer needs to be ON for this to work?',
    text: 'For performing tasks (automating likes, follows, messages & comments) your computer should be on and should not sleep.'
  },
  {
    header: 'Are the followers real?',
    text: 'Yes. A 100%. You engage with people via beatshine. You send them personalised messages, like their tracks, comment on their tracks and follow them. Hence as a social tendency they follow you back.'
  },
  {
    header: 'Is it same as Instagram bot? Should I use it?',
    text: 'Beatstars is at a place where Instagram was in 2013 and Youtube was in 2005. So if you join in early, you can automate tasks and rise to the top easily. It took 4 years for Instagram to counter bots and they could do that successfully only by 2020. Beatstars is very new platform and it will take some time for them to change their algorithms. Till the time it’s possible, hop in early and increase your social metrics and sell more beats!'
  },
  {
    header: 'Is it free?',
    text: 'We give a 2 day free trial. In these 2 days, reach about 2000+ users. You can see these metrics increase in 2 days and see if you want to continue. After that it’s less that $1 a day. (Our developers also have a home to run and mouths to feed 😛)'
  },
  {
    header: 'Can I use Beatshine from my phone?',
    text: 'No. Beatshine is a Google chrome extension which can only be used from your PC\'s chrome browser'
  },
  {
    header: 'Can I use this on Mac / Windows / Ubuntu ?',
    text: 'Beatshine is a chrome extension. You just need a chrome browser and a logged in beatstars account to make it work.'
  },
  {
    header: 'I only have a Free account on Beatstars. Will it work for me?',
    text: 'With free account you can still follow, like tracks and comment on tracks. So Like track task, comment on track task, follow and unfollow user task will work for you. You don’t get unlimited messages in free account, so message tasks won’t work for you.'
  },
  {
    header: 'What do you use for Payment?',
    text: 'We use Stripe as our Payment Partner. So you can pay by any card.'
  },
  {
    header: 'Are you in association with Beatstars?',
    text: 'No. We are a group of like minded people who just wanted to make beats and leave the engagement part on autopilot. Hence we made this software.'
  },
  {
    header: 'What are your Payment Plans?',
    text: 'Depending on the number of beatstars accounts you have, you can choose the payment plan. If you have just 1 Beatstar account and you want to use Beatshine for the same, you can choose our Silver Plan. If you have upto 3 Beatstars accounts and you want to use Beatshine will all of them, you can choose our Gold Plan. For higher plans, you can use our Diamond Account.'
  },
  {
    header: 'What is Message Bank & Comment Bank?',
    text: 'Message bank is the place to store all the messages. One of the messages would be picked randomly while messaging a user. Comment Bank is where you store all the comments. One of them is picked Randomly while commenting on the tracks.'
  },
  {
    header: 'Do I have to keep the tab running?',
    text: 'For Task Automation to run, yes you need to keep the tab open and make sure your computer doesn’t go into sleep.'
  },
  {
    header: 'Is the payment charged monthly?',
    text: <p>Yes. It’s a subscription which will be charged monthly from your account. You can cancel anytime you like by going to payment portal <a href="https://payment.beatshine.com" target="_blank">Beatshine Payment Portal</a> and logging in with your credentials.</p>
  }
]

const column1FAQs = []
const column2FAQs = []

let col1 = true
FAQItems.forEach((item) => {
  if (col1) column1FAQs.push(item)
  else column2FAQs.push(item)
  col1 = !col1
})

class Collapsibles extends React.Component {

  componentDidMount() {
    this.collapsibles1 = bulmaCollapsible.attach(".is-collapsible", {
      container: this.refs.collapsibles1
    });

    this.collapsibles2 = bulmaCollapsible.attach(".is-collapsible", {
      container: this.refs.collapsibles2
    });
  }

  render() {
    return (
      <div className="columns">
        <div ref="collapsibles1" id="accordion_first1" className="column is-6-desktop is-6-tablet is-12-mobile">
          {column1FAQs.map((item, index) => (
            <article className="message" key={index}>
              <a href={"#collapsible-message-accordion-" + index} title={item.header} data-action="collapse" style={{ textDecoration: "none" }}>
                <div className="message-header">
                  {item.header}
                </div>
              </a>
              <div
                id={"collapsible-message-accordion-" + index}
                className="message-body is-collapsible"
                data-parent="accordion_first1"
                data-allow-multiple="true"
              >
                <div className="message-body-content has-text-black">
                  {item.text}
                </div>
              </div>
            </article>
          ))}
        </div>
        <div ref="collapsibles2" id="accordion_first2" className="column is-6-desktop is-6-tablet is-12-mobile">
          {column2FAQs.map((item, index) => (
            <article className="message" key={index}>
              <a href={"#collapsible-message-accordion-" + index} title={item.header} data-action="collapse" style={{ textDecoration: "none" }}>
                <div className="message-header">
                  {item.header}
                </div>
              </a>
              <div
                id={"collapsible-message-accordion-" + index}
                className="message-body is-collapsible"
                data-parent="accordion_first2"
                data-allow-multiple="true"
              >
                <div className="message-body-content has-text-black">
                  {item.text}
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* <div ref="collapsibles" id="accordion_first">
          {FAQItems.map((item, index) => (
            <article className="message" key={index}>
              <a href={"#collapsible-message-accordion-" + index} title={item.header} data-action="collapse" style={{ textDecoration: "none" }}>
                <div className="message-header">
                  {item.header}
                </div>
              </a>
              <div
                id={"collapsible-message-accordion-" + index}
                className="message-body is-collapsible"
                data-parent="accordion_first"
                data-allow-multiple="true"
              >
                <div className="message-body-content has-text-black">
                  {item.text}
                </div>
              </div>
            </article>
          ))}
        </div> */}
      </div>
    )
  }


}

function FAQ() {
  return (
    <div className="section">
      <div className="container">
        <div className="title has-text-centered is-padded-bottom has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile" style={{ width: '100%' }}>
          <i>With less than $0.5 a day, shine your social metrics with Beatshine</i>
        </div>
        <div className="title has-text-centered is-padded-bottom has-text-white is-size-1-widescreen is-size-2-desktop is-size-3-tablet is-size-4-mobile">
          FAQs
        </div>
        <Collapsibles />
      </div>
    </div>
  );
}

export default FAQ